<template>
	<!-- 项目 -需求-原始需求详情-->
	<GModule class="container">
		<GTitle name="需求详情" style="margin: 20px;"></GTitle>
		<div class="detail-all">
			<a-form
				class="need-box"
			    :model="formState"
			    name="basic"
			    :label-col="{ span: 7}"
			    :wrapper-col="{ span: 11}"
			    autocomplete="off"
				:scrollToFirstError='true'
			    @finish="onFinish"
			    @finishFailed="onFinishFailed">
				<a-form-item 
					label="需求描述" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: true, message: '请输入需求描述' }]">
				    <a-textarea placeholder="需求描述" style="height: 150px;" v-model:value="formState.describe" />
				</a-form-item>
				<a-form-item
					label="参数/逻辑说明" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: true, message: '请输入参数/逻辑说明' }]">
				    <a-textarea placeholder="参数/逻辑说明" style="height: 90px;" v-model:value="formState.describe" />
				</a-form-item>
				<a-form-item
					label="需求图片" 
					name="imgList" 
					:colon= "false" 
					:rules="[{ required: false }]">
				    <div class="img-scroll" @click="openSwiper()">
				    	<a-image :preview="false" :width="90" :src="itm.url" v-for="(itm,ind) in imgArr" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item
					label="需求类型" 
					name="type" 
					:colon= "false" 
					:rules="[{ required: false}]">
				    卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡卡
				</a-form-item>
				<a-form-item
					label="需求模块" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: false }]">
				    <a-breadcrumb separator=">" style="margin-bottom: 14px;">
				        <a-breadcrumb-item>需求</a-breadcrumb-item>
				        <a-breadcrumb-item>需求2</a-breadcrumb-item>
				        <a-breadcrumb-item>Application List</a-breadcrumb-item>
				        <a-breadcrumb-item>An Application</a-breadcrumb-item>
				    	<a-breadcrumb-item>需求</a-breadcrumb-item>
				    	<a-breadcrumb-item>需求2</a-breadcrumb-item>
				    	<a-breadcrumb-item>Application List</a-breadcrumb-item>
				    	<a-breadcrumb-item>An Application</a-breadcrumb-item>
				     </a-breadcrumb>
				</a-form-item>
				<a-form-item
					label="需求ID" 
					name="type" 
					:colon= "false" 
					:rules="[{ required: false}]">
				    002
				</a-form-item>
				<a-form-item
					label="状态" 
					name="type" 
					:colon= "false" 
					:rules="[{ required: false}]">
				    已完成
				</a-form-item>
				<a-form-item
					label="相关任务" 
					name="type" 
					:colon= "false" 
					:rules="[{ required: false}]">
				    <a-table
				    	class="ttable" 
				    	sticky 
				    	:columns="columnsHe" 
				    	:data-source="data" 
				    	:scroll="{ x: 100,y:250 }" 
				    	bordered
				    	:pagination="false">
				      <template #bodyCell="{ column ,record}">
				        <template v-if="column.key === 'operation'">
				    		<div class="caozuo">
				    			<p>修改</p>
				    			<p>删除</p>
				    			<p>详情</p>
				    		</div>
				    	</template>
				      </template>
				    </a-table>
				</a-form-item>
				<a-form-item
					label="已完成页面图" 
					name="imgList" 
					:colon= "false" 
					:rules="[{ required: false }]">
				    <div class="overimg-box">
				    	<p>静态</p>
						<div class="overimg" @click="openSwiper()">
							<a-image :preview="false" :width="65" :src="itm.url" v-for="(itm,ind) in imgArr" :key="ind"/>
						</div>
				    </div>
					<div class="overimg-box" style="margin-top: 10px;">
						<p>联调</p>
						<div class="overimg" @click="openSwiper()">
							<a-image :preview="false" :width="65" :src="itm.url" v-for="(itm,ind) in imgArr2" :key="ind"/>
						</div>
					</div>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:11 }">
				  <a-button class="btn clo1" @click="openDownloadImgs()">下载</a-button>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10}">
				  <a-button class="btn clo6">返回</a-button>
				  <a-button class="btn clo3">建任务</a-button>
				</a-form-item>
			</a-form>
		</div>
	</GModule>	
</template>

<script>
	export default{
		name:'TaskProjectNeed',
		data(){
			return{
				formState :{
					type:'',
					describe:'',
					explain:'',
					imgList:[]
				},
				imgArr:[
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false}
				],
				imgArr2:[
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false}
				],
				columnsHe: [{
				    title: '任务ID',
				    width:70,
				    dataIndex: 'index',
				    key: 'index',
				    fixed: 'left',
				  }, {
				    title: '任务描述',
				    width: 100,
				    dataIndex: 'name',
				    key: 'describe',
				    fixed: 'left',
				  }, {
				    title: '指派给',
				    width: 70,
				    dataIndex: 'age',
				    key: 'age'
				  }, {
				    title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width: 100,
				}],
				data: [{
				  key: '1',
				  name: 'John Brown',
				  age: 32,
				  address: 'New York No. 1 Lake Park',
				}, {
				  key: '2',
				  name: 'Jim Green大家聚聚军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军',
				  age: 42,
				  address: 'London No. 1 Lake Park',
				}, {
				  key: '3',
				  name: 'Joe Black',
				  age: 32,
				  images:[],
				  address: 'Sidney No. 1 Lake Park',
				}, {
				  key: '4',
				  name: 'Jim Red',
				  age: 32,
				  address: 'London No. 2 Lake Park',
				},{
				  key: '5',
				  name: 'John Brown',
				  age: 32,
				  address: 'New York No. 1 Lake Park',
				}, {
				  key: '6',
				  name: 'Jim Green',
				  age: 42,
				  address: 'London No. 1 Lake Park',
				}, {
				  key: '7',
				  name: 'Joe Black',
				  age: 32,
				  address: 'Sidney No. 1 Lake Park',
				}, {
				  key: '8',
				  name: 'Jim Red',
				  age: 32,
				  address: 'London No. 2 Lake Park',
				}],
			}
		},
		mounted() {
			this.$store.commit('setStaticImgs',this.imgArr)
			this.$store.commit('setAlignmentImgs',this.imgArr2)
		},
		methods:{
			openSwiper(){
			  // 弹出图片列表
			  this.$store.commit('setSwiper',1)
			},
			openDownloadImgs(){
				// 弹出图片下载
				this.$store.commit('setImgCheck',1)
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0 0 50px 0;
	overflow: hidden;
	.detail-all{
		display: flex;
		justify-content: center;
		padding: 30px 0;
		max-height: 92%;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 0px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
		&:hover{
			&::-webkit-scrollbar {
				width: 1px;
			}
		}
		.need-box{
			.btn{
				margin-right: 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;

					}
				}
			}
			.overimg-box{
				display: flex;
				align-items: center;
				p{
					width: 50px;
					margin: 0;
					padding: 0;
					font-size: 12px;
				}
				.overimg{
					display: flex;
					height: 60px;
					overflow: hidden;
				}
			}
		}
	}
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}
</style>